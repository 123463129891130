import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import { useCart } from "./CartContext";
import { DOMAIN } from "../../../../config/env";
import { RESSOURCE } from "../../../../config/constants";
import TrashIcon from "../../../../components/ui/icons/TrashIcon";
import PhoneIcon from "../../../../components/ui/icons/PhoneIcon";
import DeliveryIcon from "../../../../components/ui/icons/DeliveryIcon";
import ShopcartIcon from "../../../../components/ui/icons/ShopcartIcon";
import useAuth from "../../../../hooks/useAuth";
import {
  BoutiqueCommandeMail,
  BoutiqueAddCommande,
} from "../../../../services/boutique_api";
import { PATH } from "../../../../config/constants";
import ModalCommande from "./ModalCommande";

const Panier = () => {
  const { user, magasin } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const { cartItems, removeFromCart, updateQuantity } = useCart();
  const [nomMag, setMagasin] = useState("");
  const [nomPrenom, setNomPrenom] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [adresseLivraison, setAdresseLivraison] = useState("");
  const [commentaire, setCommentaire] = useState("");
  const [message, setMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      magasin: nomMag,
      nomPrenom: nomPrenom,
      telephone: telephone,
      email: email,
      adresseLivraison: adresseLivraison,
      commentaire: commentaire,
      montant: totalWithDiscount,
      mag_code: magasin.mag_code,
      user: user.name,
      panier: cartItems,
    };

    try {
      const responseData = await BoutiqueAddCommande(data);

      if (responseData.success) {
        setIsSuccess(true);
        setMessage(responseData.success);
        setModalOpen(false);
        data.cmd_code = responseData.cmd_code;

        cartItems.forEach((item) => removeFromCart(item.id));
        try {
          const mailResponse = await BoutiqueCommandeMail(data);
          if (!mailResponse.success) {
            throw new Error("Erreur lors de l'envoi du mail");
          }
        } catch (mailError) {
          setIsError(true);
          setMessage(mailError.message || "Erreur lors de l'envoi du mail");
        }
      }
    } catch (error) {
      setIsError(true);
      setMessage("Erreur lors de l'envoi du mail");
    }
  };

  const totalWithDiscount = cartItems.reduce(
    (acc, item) =>
      acc +
      (item.produit.pr_pvht_remise !== 0
        ? item.produit.prix_avec_remise
        : item.produit.pr_pvht) *
        item.quantite,
    0
  );
  const getImageUrl = (item) => {
    return item.produit.images && item.produit.images.length > 0
      ? `${DOMAIN.URL}/${RESSOURCE.BOUTIQUE}/${item.produit.images[0].img_image}`
      : "path/to/default/image.jpg";
  };

  const openModal = () => {
    // Pré-remplissage des champs avant l'ouverture de la modal
    setMagasin(magasin.mag_nom);
    const adresseConcat = [
      magasin.mag_adresse1,
      magasin.mag_adresse2,
      magasin.mag_cp,
      magasin.mag_ville,
    ]
      .filter(Boolean) // pour enlever les champs vides ou null
      .join(" - ");
    setAdresseLivraison(adresseConcat);
    setModalOpen(true);
  };

  if (cartItems.length === 0) {
    return (
      <div className="cart-empty">
        <div className="titre-panier">Votre panier</div>
        {isSuccess && (
          <div className="success-banner">
            Votre commande a bien été validée !
          </div>
        )}
        <div className="empty-banner">
          <p>Votre panier est vide. Visitez la</p>
          <Link to={PATH.BOUTIQUE}>boutique</Link>
        </div>
      </div>
    );
  }

  return (
    <div className="panier">
      <div className="titre-panier">Votre panier</div>
      {isError && <div className="error-banner">{message}</div>}
      <div className="cart-main-container">
        <div className="products">
          {cartItems.map((item) => (
            <div className="product-card" key={item.id}>
              <div class="product-image-container">
                <img
                  src={getImageUrl(item)}
                  alt={item.produit.pr_designation}
                  className="product-image"
                />
              </div>
              <div className="product-info">
                <h3>{item.produit.pr_designation}</h3>
                <p>Référence: {item.produit.pr_reference}</p>
                {item.taille_id !== 0 && (
                  <p>Taille: {item.taille.ta_libelle}</p>
                )}
                {item.couleur_libelle !== 0 && (
                  <p>Couleur: {item.couleur.cou_libelle}</p>
                )}
                {item.logo_id !== 0 && <p>Logo: {item.logo.lo_libelle}</p>}
              </div>
              <div className="product-actions">
                <p className="quantity-label">Quantité :</p>
                <div className="quantity-container">
                  <button
                    className="quantity-btn decrement"
                    onClick={() => updateQuantity(item.id, -1)}
                  >
                    -
                  </button>
                  <div className="quantity-value">{item.quantite}</div>
                  <button
                    className="quantity-btn increment"
                    onClick={() => updateQuantity(item.id, 1)}
                  >
                    +
                  </button>
                </div>
                <div className="product-price-container">
                  <span className="product-price-label">PU HT</span>{" "}
                  {item.pr_pvht_remise !== 0 ? (
                    <>
                      <span className="product-price">
                        {item.produit.prix_avec_remise.toFixed(2)} €
                      </span>
                    </>
                  ) : (
                    <span className="product-price">
                      {item.produit.pr_pvht.toFixed(2)} €
                    </span>
                  )}
                </div>
                <div className="product-price-container">
                  <span className="product-price-label">Montant HT</span>{" "}
                  {item.produit.pr_pvht_remise !== 0 ? (
                    <>
                      <span className="product-price">
                        {(
                          item.produit.prix_avec_remise * item.quantite
                        ).toFixed(2)}{" "}
                        €
                      </span>
                    </>
                  ) : (
                    <span className="product-price">
                      {(item.produit.pr_pvht * item.quantite).toFixed(2)} €
                    </span>
                  )}
                </div>
              </div>
              <button
                className="remove-btn"
                onClick={() => removeFromCart(item.id)}
              >
                <TrashIcon height={20} width={20} />
              </button>
            </div>
          ))}
          <Link to={PATH.BOUTIQUE} className="continue-shopping-btn">
            <ShopcartIcon height={18} width={18} />
            <span>Continuer mes achats</span>
          </Link>
        </div>
        <div className="cart-details-wrapper">
          <div className="cart-total-container">
            {cartItems.map((item) => (
              <div className="item-detail">
                <span className="item-title">
                  {item.produit.pr_designation}
                </span>
                <span className="item-price">
                  {item.produit.pr_pvht_remise !== 0 ? (
                    <>
                      <span className="product-price">
                        {(
                          item.produit.prix_avec_remise * item.quantite
                        ).toFixed(2)}{" "}
                        €
                      </span>
                    </>
                  ) : (
                    <span className="product-price">
                      {(item.produit.pr_pvht * item.quantite).toFixed(2)} €
                    </span>
                  )}
                </span>
              </div>
            ))}
            <div className="separator"></div>{" "}
            <div className="cart-total">
              Total HT <span>{totalWithDiscount.toFixed(2)} €</span>
            </div>
            <div className="cart-buttons">
              <button className="checkout-btn" onClick={openModal}>
                Valider la commande
              </button>
            </div>
          </div>
          <div className="cart-details-container">
            <div className="icon-text-item">
              <DeliveryIcon height={40} width={40} />
              <span className="item-text">Montant hors frais d'expédition</span>
            </div>
            <div className="icon-text-item">
              <PhoneIcon height={30} width={30} />
              <span className="item-text">
                Contactez-nous au 02 28 02 29 34
              </span>
            </div>
          </div>
        </div>
      </div>

      <ModalCommande
        isOpen={isModalOpen}
        setModalOpen={setModalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={handleSubmit}
        magasin={magasin}
        nomMag={nomMag}
        setMagasin={setMagasin}
        nomPrenom={nomPrenom}
        setNomPrenom={setNomPrenom}
        telephone={telephone}
        setTelephone={setTelephone}
        email={email}
        setEmail={setEmail}
        adresseLivraison={adresseLivraison}
        setAdresseLivraison={setAdresseLivraison}
        commentaire={commentaire}
        setCommentaire={setCommentaire}
        message={message}
        setMessage={setMessage}
      />
    </div>
  );
};

export default Panier;

import React from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ClearIcon from "@mui/icons-material/Clear";
import useAuth from "../../../../hooks/useAuth";

const ZoneDistribution = ({
  zones,
  selectedZone,
  handleZoneChange,
  uslData,
  handleDelete,
  openAddZoneModal,
  openEditZoneModal,
  openImportExcelModal,
  handleDownload,
  handleEmptyZone,
  handleDuplicateZone,
  handleDeleteZone,
}) => {
  const { user } = useAuth();
  const roleId = user?.roles?.[0]?.id;

  const hasAccess = [1, 3].includes(roleId);
  const sortedUslData = [...uslData].sort((a, b) => {
    const libelleA = a.usl[0]?.usl_libelle || "";
    const libelleB = b.usl[0]?.usl_libelle || "";

    if (!libelleA && libelleB) return 1;
    if (!libelleB && libelleA) return -1;

    return libelleA.localeCompare(libelleB, "fr", { sensitivity: "base" });
  });

  return (
    <div className="zone-distribution-container">
      <div className="zoneSelect center-select">
        <select
          id="zoneSelect"
          className="styled-select"
          onChange={handleZoneChange}
          value={selectedZone || ""}
        >
          <option value="" disabled={selectedZone !== null}>
            Selectionnez une zone
          </option>
          {zones.map((zone) => (
            <option key={zone.id_mag_zone} value={zone.id_mag_zone}>
              {zone.cmz_libelle}
            </option>
          ))}
        </select>

        <div className="button-group">
          <a href={handleDownload} className="btn-action" download>
            <DownloadIcon />
            Télécharger
          </a>
          {hasAccess && (
            <>
              <button className="btn-action" onClick={openAddZoneModal}>
                <AddIcon />
                Ajouter une zone
              </button>
              <button className="btn-action" onClick={openEditZoneModal}>
                <EditIcon />
                Modifier nom de la zone
              </button>
              <button className="btn-action" onClick={openImportExcelModal}>
                <UploadFileIcon />
                Importer
              </button>
              <button className="btn-action" onClick={handleDuplicateZone}>
                <ContentCopyIcon />
                Dupliquer
              </button>
              <button className="btn-action" onClick={handleEmptyZone}>
                <DeleteForeverIcon />
                Vider la zone
              </button>
              <button className="btn-action" onClick={handleDeleteZone}>
                <ClearIcon />
                Supprimer la zone
              </button>
            </>
          )}
        </div>
      </div>
      {selectedZone ? (
        sortedUslData.length > 0 ? (
          <div className="table-container-dist scroll">
            <table className="distribution-table">
              <thead>
                <tr className="distribution-thead">
                  <th rowSpan="2">CODE USL</th>
                  <th rowSpan="2">Libelle (communes)</th>
                  <th colSpan="3">Quantités GEO</th>
                  <th colSpan="2">Zone OUI PUB</th>
                  <th rowSpan="2">TOTAL BOITES</th>
                  {hasAccess && <th rowSpan="2">ACTIONS</th>}
                </tr>
                <tr className="distribution-thead">
                  <th className="sub-header">A</th>
                  <th className="sub-header">B</th>
                  <th className="sub-header">C</th>
                  <th className="sub-header">D1</th>
                  <th className="sub-header">D2</th>
                </tr>
                <tr></tr>
              </thead>
              <tbody>
                {sortedUslData.map((usl) => (
                  <React.Fragment key={usl.usl_code}>
                    {usl.usl.map((periode) => (
                      <tr
                        key={periode.usl_code}
                        style={{
                          backgroundColor:
                            periode.usl_etat === -1 ? "#ffe5e5" : "transparent",
                        }}
                      >
                        <td>{periode.usl_code}</td>
                        <td>{periode.usl_libelle}</td>
                        <td>{periode.usl_qte_A}</td>
                        <td>{periode.usl_qte_B}</td>
                        <td>{periode.usl_qte_C}</td>
                        <td>{periode.usl_qte_D1}</td>
                        <td>{periode.usl_qte_D2}</td>
                        <td>
                          {periode.usl_qte_A +
                            periode.usl_qte_B +
                            periode.usl_qte_C +
                            periode.usl_qte_D1 +
                            periode.usl_qte_D2}
                        </td>
                        {hasAccess && (
                          <td>
                            <button
                              className="button-action deleteButton"
                              onClick={() => handleDelete(usl.usl_code)}
                            >
                              Supprimer
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
                <tr className="distribution-head">
                  <td colSpan="2">Totaux</td>
                  <td>
                    {uslData.reduce(
                      (acc, usl) =>
                        acc +
                        usl.usl.reduce(
                          (subAcc, periode) => subAcc + periode.usl_qte_A,
                          0
                        ),
                      0
                    )}
                  </td>
                  <td>
                    {uslData.reduce(
                      (acc, usl) =>
                        acc +
                        usl.usl.reduce(
                          (subAcc, periode) => subAcc + periode.usl_qte_B,
                          0
                        ),
                      0
                    )}
                  </td>
                  <td>
                    {uslData.reduce(
                      (acc, usl) =>
                        acc +
                        usl.usl.reduce(
                          (subAcc, periode) => subAcc + periode.usl_qte_C,
                          0
                        ),
                      0
                    )}
                  </td>
                  <td>
                    {uslData.reduce(
                      (acc, usl) =>
                        acc +
                        usl.usl.reduce(
                          (subAcc, periode) => subAcc + periode.usl_qte_D1,
                          0
                        ),
                      0
                    )}
                  </td>
                  <td>
                    {uslData.reduce(
                      (acc, usl) =>
                        acc +
                        usl.usl.reduce(
                          (subAcc, periode) => subAcc + periode.usl_qte_D2,
                          0
                        ),
                      0
                    )}
                  </td>
                  <td>
                    {uslData.reduce(
                      (acc, usl) =>
                        acc +
                        usl.usl.reduce(
                          (subAcc, periode) =>
                            subAcc +
                            periode.usl_qte_A +
                            periode.usl_qte_B +
                            periode.usl_qte_C +
                            periode.usl_qte_D1 +
                            periode.usl_qte_D2,
                          0
                        ),
                      0
                    )}
                  </td>
                  {hasAccess && <td></td>}
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div className="message">
            <p>Pas de donnée disponible !</p>
          </div>
        )
      ) : (
        <div className="message">
          <p>Sélectionnez une zone pour afficher le tableaux !</p>
        </div>
      )}
    </div>
  );
};

export default ZoneDistribution;

import React from "react";

const ModalCommande = ({
  isOpen,
  setModalOpen,
  onSubmit,
  magasin,
  nomMag,
  setMagasin,
  setNomPrenom,
  setTelephone,
  setEmail,
  adresseLivraison,
  setAdresseLivraison,
  setCommentaire,
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Valider ma commande</h2>
        <hr />
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label>Magasin *</label>
            <input
              type="text"
              placeholder="Nom du magasin"
              value={nomMag}
              onChange={(e) => setMagasin(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Nom Prénom *</label>
            <input
              type="text"
              placeholder="Votre nom et prénom"
              onChange={(e) => setNomPrenom(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Téléphone *</label>
            <input
              type="tel"
              placeholder="Votre numéro de téléphone"
              onChange={(e) => setTelephone(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Email *</label>
            <input
              type="email"
              placeholder="Votre email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Adresse de livraison *</label>
            <textarea
              className="comment-textarea"
              placeholder="Votre adresse de livraison"
              value={adresseLivraison}
              onChange={(e) => setAdresseLivraison(e.target.value)}
              required
            ></textarea>
          </div>
          <div className="form-group">
            <label>Commentaires</label>
            <textarea
              className="comment-textarea"
              placeholder="Vos commentaires"
              onChange={(e) => setCommentaire(e.target.value)}
            ></textarea>
          </div>
          <div className="form-group">
            <div>
              <input type="checkbox" id="conditions" required />
              <label htmlFor="conditions">
                J'accèpte les conditions générales de vente *
              </label>
            </div>
          </div>
          <div className="obligatory-note">*champ obligatoire</div>
          <hr />
          <div className="form-actions">
            <button
              className="close-modal-btn"
              type="button"
              onClick={() => setModalOpen(false)}
            >
              Fermer
            </button>
            <button type="submit" className="submit-btn">
              Valider
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalCommande;
